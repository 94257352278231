import { DeprecatedIconType } from "@src/deprecatedDesignSystem/deprecatedIcons";
import {
  DeprecatedColor,
  DeprecateTone,
  darkenColor,
  deprecatedShadeColor,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import DeprecatedIcon, {
  DeprecatedIconProps,
} from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Spinner from "@src/deprecatedDesignSystem/components/Spinner";
import Text, { TextFontWeight, TextType } from "@ui/text";
import BetaTag from "@src/components/ui/BetaTag";
import { StyleDeclaration, StyleSheet, css } from "aphrodite";
import React, { CSSProperties, FC, MouseEventHandler, useMemo } from "react";
import { Route } from "nextjs-routes";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import useBrandColor from "@src/hooks/useBrandColor";

type Props = {
  borderRadius?: "8px" | "40px";
  height?: "32px" | "40px";
  leftIcon?: DeprecatedIconType;
  leftIconProps?: Partial<DeprecatedIconProps>;
  rightIcon?: DeprecatedIconType;
  rightIconProps?: Partial<DeprecatedIconProps>;
  rightComponent?: React.ReactNode;
  customIconColor?: DeprecateTone | DeprecatedColor;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  variant?:
    | "Primary"
    | "Secondary"
    | "Tertiary"
    | "Outline"
    | "No Outline"
    | "Destroy"
    | "Gray"
    | "Black"
    | "Brand"
    | "AI";
  loading?: boolean;
  disabled?: boolean | null | undefined;
  text?: React.ReactNode;
  textType?: TextType;
  textFontWeight?: TextFontWeight;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onClickGoToLink?: Route;
  onClickGoToExternalUrl?: string;
  linkOpenInNewTab?: boolean;
  style?: CSSProperties;
  styleDeclaration?: StyleDeclaration;
  active?: boolean;
  fontColor?: DeprecateTone | DeprecatedColor;
  justifyContent?: CSSProperties["justifyContent"];
  beta?: boolean;
  "data-testid"?: string;
  "data-html2canvas-ignore"?: boolean;
};

const Button: FC<Props> = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      type = "button",
      borderRadius = "8px",
      height = "32px",
      leftIcon,
      rightIcon,
      leftIconProps,
      rightIconProps,
      customIconColor,
      variant = "Primary",
      loading = false,
      disabled = false,
      text,
      textType = "P2",
      textFontWeight = "SemiBold",
      onClick,
      onClickGoToLink,
      onClickGoToExternalUrl,
      linkOpenInNewTab,
      style,
      active,
      styleDeclaration,
      justifyContent = "center",
      fontColor: fontColorProp,
      beta,
      rightComponent,
      ...props
    },
    ref,
  ) => {
    let defaultStyle = styles.defaultPrimary;
    let disabledStyle = styles.disabledPrimary;
    let paddingStyle = styles.padding32;
    let fontColor: string = deprecatedTones.white;
    let iconColor: string = deprecatedTones.white;
    const brandColor = useBrandColor();
    const brandStyles = useMemo(() => {
      return StyleSheet.create({
        defaultBrand: {
          backgroundColor: brandColor,
          border: `1px solid ${brandColor}`,
          outline: "none",
          ":hover": {
            backgroundColor: darkenColor(brandColor, 0.1),
            border: `1px solid ${darkenColor(brandColor, 0.1)}`,
            outline: "none",
            cursor: "pointer",
          },
          ":focus-visible": {
            backgroundColor: brandColor,
            border: `1px solid ${brandColor}`,
            outline: `2px solid ${deprecatedShadeColor(brandColor, 0.9)}`,
          },
        },
        disabledBrand: {
          backgroundColor: deprecatedShadeColor(brandColor, 0.9),
          border: `1px solid ${deprecatedShadeColor(brandColor, 0.9)}`,
          outline: "none",
          ":hover": {
            cursor: "not-allowed",
          },
        },
      });
    }, [brandColor]);

    switch (variant) {
      case "Secondary":
        defaultStyle = styles.defaultSecondary;
        disabledStyle = styles.disabledSecondary;
        break;
      case "Tertiary":
        defaultStyle = styles.defaultTertiary;
        disabledStyle = styles.disabledTertiary;
        if (disabled) {
          fontColor = deprecatedTones.gray7;
          iconColor = deprecatedTones.gray7;
        } else {
          fontColor = deprecatedTones.purple7;
          iconColor = deprecatedTones.purple7;
        }
        break;
      case "Destroy":
        defaultStyle = styles.defaultDestroy;
        disabledStyle = styles.disabledDestroy;
        paddingStyle = height === "40px" ? styles.padding40 : styles.padding32;
        break;
      case "Outline":
        defaultStyle = styles.defaultOutline;
        disabledStyle = styles.disabledOutline;
        fontColor = deprecatedTones.black;
        iconColor = deprecatedTones.gray10;
        paddingStyle = height === "40px" ? styles.padding40 : styles.padding32;
        break;
      case "No Outline":
        fontColor = deprecatedTones.black;
        iconColor = deprecatedTones.gray10;
        defaultStyle = styles.defaultNoOutline;
        disabledStyle = styles.disabledNoOutline;
        paddingStyle = styles.paddingNoOutline;
        break;
      case "Gray":
        defaultStyle = styles.defaultGray;
        disabledStyle = styles.disabledGray;
        fontColor = disabled ? deprecatedTones.gray6 : deprecatedTones.black;
        iconColor = disabled ? deprecatedTones.gray6 : deprecatedTones.gray10;
        break;
      case "Black":
        defaultStyle = styles.defaultBlack;
        disabledStyle = styles.disabledBlack;
        fontColor = disabled ? deprecatedTones.gray10 : deprecatedTones.white;
        iconColor = disabled ? deprecatedTones.gray10 : deprecatedTones.white;
        break;
      case "Brand":
        defaultStyle = brandStyles.defaultBrand;
        disabledStyle = brandStyles.disabledBrand;
        break;
      case "AI":
        defaultStyle = styles.defaultAI;
        disabledStyle = styles.disabledAI;
        break;
    }
    if (customIconColor) {
      iconColor = customIconColor;
    }
    const buttonComponent = useMemo(() => {
      return (
        <button
          type={type}
          ref={ref}
          className={css(
            styles.container,
            paddingStyle,
            !text && height === "32px" && styles.width32,
            !text && height === "40px" && styles.width40,
            disabled || loading ? disabledStyle : defaultStyle,
            active && styles.active,
            styleDeclaration,
          )}
          style={{
            height,
            borderRadius,
            justifyContent,
            ...style,
          }}
          disabled={disabled || loading}
          onClick={(e) => {
            if (onClick && !disabled && !loading) {
              onClick(e);
            }
          }}
          {...props}
        >
          {leftIcon && (
            <DeprecatedIcon
              type={leftIcon}
              color={iconColor}
              iconSize={leftIcon === "chevron-down" ? 32 : 20}
              style={{ visibility: loading ? "hidden" : "visible" }}
              {...leftIconProps}
            />
          )}
          {!!text && (
            <Text
              type={textType}
              fontWeight={textFontWeight}
              color={fontColorProp || fontColor}
              styleDeclaration={styles.textContainer}
              style={{ visibility: loading ? "hidden" : "visible" }}
            >
              {text}
            </Text>
          )}
          {beta && <BetaTag />}
          {rightIcon && !!text && (
            <DeprecatedIcon
              type={rightIcon}
              color={iconColor}
              iconSize={rightIcon === "chevron-down" ? 32 : 20}
              style={{ visibility: loading ? "hidden" : "visible" }}
              {...rightIconProps}
            />
          )}
          {rightComponent}
          {loading && (
            <Spinner
              styleDeclaration={styles.loader}
              size={20}
              color={iconColor}
            />
          )}
        </button>
      );
    }, [
      active,
      beta,
      borderRadius,
      defaultStyle,
      disabled,
      disabledStyle,
      fontColor,
      fontColorProp,
      height,
      iconColor,
      justifyContent,
      leftIcon,
      leftIconProps,
      loading,
      onClick,
      paddingStyle,
      props,
      ref,
      rightIcon,
      rightIconProps,
      style,
      styleDeclaration,
      text,
      textFontWeight,
      textType,
      type,
      rightComponent,
    ]);
    if (onClickGoToLink || onClickGoToExternalUrl) {
      return (
        <AutoLayout
          linkOpenInNewTab={linkOpenInNewTab}
          externalUrl={onClickGoToExternalUrl}
          link={onClickGoToLink}
        >
          {buttonComponent}
        </AutoLayout>
      );
    } else {
      return buttonComponent;
    }
  },
);

const styles = StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
    padding: "4px 8px",
    transitionProperty: "all",
    transitionDuration: "0.15s",
    transitionFunction: "cubic-bezier(0.45, 0.25, 0.25, 1)",
    position: "relative",
  },
  padding32: {
    padding: "4px 8px",
  },
  padding40: {
    padding: "4px 12px",
  },
  paddingNoOutline: {
    padding: 4,
  },
  width32: {
    width: 32,
  },
  width40: {
    width: 40,
  },
  textContainer: {
    paddingRight: 4,
    paddingLeft: 4,
    userSelect: "none",
  },
  defaultPrimary: {
    backgroundColor: deprecatedTones.blue9,
    border: `1px solid ${deprecatedTones.blue9}`,
    outline: "none",
    ":hover": {
      backgroundColor: deprecatedTones.blue10,
      border: `1px solid ${deprecatedTones.blue10}`,
      outline: "none",
      cursor: "pointer",
    },
    ":focus-visible": {
      backgroundColor: deprecatedTones.blue9,
      border: `1px solid ${deprecatedTones.blue9}`,
      outline: `2px solid ${deprecatedTones.blue3}`,
    },
  },
  defaultSecondary: {
    backgroundColor: deprecatedTones.purple8,
    border: `1px solid ${deprecatedTones.purple8}`,
    outline: "none",
    ":hover": {
      backgroundColor: deprecatedTones.purple9,
      border: `1px solid ${deprecatedTones.purple9}`,
      outline: "none",
      cursor: "pointer",
    },
    ":focus-visible": {
      backgroundColor: deprecatedTones.purple8,
      border: `1px solid ${deprecatedTones.purple8}`,
      outline: `2px solid ${deprecatedTones.purple3}`,
    },
  },
  defaultTertiary: {
    backgroundColor: deprecatedTones.purple1,
    border: `1px solid ${deprecatedTones.purple1}`,
    outline: "none",
    ":hover": {
      backgroundColor: deprecatedTones.purple2,
      border: `1px solid ${deprecatedTones.purple2}`,
      outline: "none",
      cursor: "pointer",
    },
    ":focus-visible": {
      backgroundColor: deprecatedTones.purple1,
      border: `1px solid ${deprecatedTones.purple1}`,
      outline: `2px solid ${deprecatedTones.white}`,
    },
  },
  defaultGray: {
    backgroundColor: deprecatedTones.gray4Alpha,
    border: `1px solid ${deprecatedTones.gray4Alpha}`,
    outline: "none",
    ":hover": {
      backgroundColor: deprecatedTones.gray5Alpha,
      border: `1px solid ${deprecatedTones.gray5Alpha}`,
      outline: "none",
      cursor: "pointer",
    },
    ":focus-visible": {
      backgroundColor: deprecatedTones.gray4Alpha,
      border: `1px solid ${deprecatedTones.gray4Alpha}`,
      outline: `2px solid ${deprecatedTones.gray3Alpha}`,
    },
  },
  disabledGray: {
    backgroundColor: deprecatedTones.gray3Alpha,
    border: `1px solid ${deprecatedTones.gray3Alpha}`,
    outline: "none",
    ":hover": {
      cursor: "not-allowed",
    },
  },
  defaultBlack: {
    backgroundColor: deprecatedTones.black,
    border: `1px solid ${deprecatedTones.black}`,
    outline: "none",
    ":hover": {
      backgroundColor: deprecatedTones.gray12,
      border: `1px solid ${deprecatedTones.gray12}`,
      outline: "none",
      cursor: "pointer",
    },
    ":focus-visible": {
      backgroundColor: deprecatedTones.gray5Alpha,
      border: `1px solid ${deprecatedTones.gray5Alpha}`,
      outline: `2px solid ${deprecatedTones.gray4Alpha}`,
    },
  },
  disabledBlack: {
    backgroundColor: deprecatedTones.black,
    border: `1px solid ${deprecatedTones.black}`,
    outline: "none",
    ":hover": {
      cursor: "not-allowed",
    },
  },
  disabledPrimary: {
    backgroundColor: deprecatedTones.blue3,
    border: `1px solid ${deprecatedTones.blue3}`,
    outline: "none",
    ":hover": {
      cursor: "not-allowed",
    },
  },
  disabledSecondary: {
    backgroundColor: deprecatedTones.purple3,
    border: `1px solid ${deprecatedTones.purple3}`,
    outline: "none",
    ":hover": {
      cursor: "not-allowed",
    },
  },
  disabledTertiary: {
    backgroundColor: deprecatedTones.gray4Alpha,
    border: `1px solid ${deprecatedTones.gray4Alpha}`,
    outline: "none",
    ":hover": {
      cursor: "not-allowed",
    },
  },
  defaultDestroy: {
    backgroundColor: deprecatedTones.red9,
    border: `1px solid ${deprecatedTones.red9}`,
    outline: "none",
    ":hover": {
      backgroundColor: deprecatedTones.red10,
      border: `1px solid ${deprecatedTones.red10}`,
      outline: "none",
      cursor: "pointer",
    },
    ":focus-visible": {
      backgroundColor: deprecatedTones.red9,
      border: `1px solid ${deprecatedTones.red9}`,
      outline: `2px solid ${deprecatedTones.red4}`,
    },
  },
  disabledDestroy: {
    backgroundColor: deprecatedTones.red4,
    border: `1px solid ${deprecatedTones.red4}`,
    outline: "none",
    ":hover": {
      cursor: "not-allowed",
    },
  },
  defaultOutline: {
    backgroundColor: deprecatedTones.white,
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
    outline: "none",
    ":hover": {
      backgroundColor: deprecatedTones.gray4Alpha,
      border: `1px solid ${deprecatedTones.gray5Alpha}`,
      outline: "none",
      cursor: "pointer",
    },
    ":focus-visible": {
      backgroundColor: deprecatedTones.white,
      border: `1px solid ${deprecatedTones.gray5Alpha}`,
      outline: `2px solid ${deprecatedTones.blue3}`,
    },
  },
  disabledOutline: {
    backgroundColor: deprecatedTones.gray4Alpha,
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
    outline: "none",
    ":hover": {
      cursor: "not-allowed",
    },
  },
  defaultNoOutline: {
    backgroundColor: "transparent",
    border: `1px solid transparent`,
    outline: "none",
    ":hover": {
      backgroundColor: deprecatedTones.gray5Alpha,
      outline: "none",
      cursor: "pointer",
    },
    ":focus-visible": {
      backgroundColor: deprecatedTones.white,
      outline: `2px solid ${deprecatedTones.gray5Alpha}`,
    },
  },
  disabledNoOutline: {
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
    ":hover": {
      cursor: "not-allowed",
    },
    opacity: 0.5,
  },
  loader: {
    position: "absolute",
  },
  active: {
    outline: `2px solid ${deprecatedTones.blue3}`,
  },
  defaultAI: {
    background:
      "linear-gradient(90deg, #BD82FA 24.44%, #FE508F 88.67%, #FB99A6 100%), #FFF",
  },
  disabledAI: {
    background:
      "linear-gradient(90deg, #BD82FA 24.44%, #FE508F 88.67%, #FB99A6 100%), #FFF",
    outline: "none",
    opacity: 0.6,
    ":hover": {
      cursor: "not-allowed",
    },
  },
});

export default Button;
